import styled, { css } from "styled-components";
import Img, { FixedObject } from "gatsby-image";

import { fadeIn, fadeOut } from "../../../../../../utils/animations";
import {
  H4,
  H5,
  TEXT_BODY_1,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 160px 24px 88px 24px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const Title = styled.h2`
  ${H4}
  width: 100%;
  margin-bottom: 8px;
  color: #151515;
  white-space: pre-line;
`;

export const Subtitle = styled.h3`
  ${H4}
  margin: 0;
  color: #013cff;
  white-space: pre-line;
`;

export const Description = styled.div`
  ${TEXT_BODY_1}
  margin-top: 12px;
`;

export const TechnologiesWrapper = styled.div`
  position: relative;
  flex: 1;
  margin: 40px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ItemWrapper = styled.div`
  position: relative;
  width: calc(100vw - 2 * 24px);
  height: calc(100vw - 2 * 24px);
  margin: 12px 0;
  background: #013cff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const TextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Text = styled.h1`
  ${H5}
  width: 100%;
  margin: 0;
  padding: 0 24px;
  color: #ffffff;
  white-space: pre-line;
  text-transform: uppercase;
`;

export const HorizontalDivider = styled.hr`
  width: 88px;
  height: 6px;
  margin: 32px 0 36px 0;
  background: #151515;
`;

interface WrapperProps {
  open?: number;
}

export const CloseModalWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  user-select: none;
  z-index: 1;
  animation: ${(props: WrapperProps) =>
    props.open
      ? css`
          ${fadeIn} 300ms linear
        `
      : css`
          ${fadeOut} 300ms linear
        `};
  animation-fill-mode: forwards;

  svg {
    cursor: pointer;
    width: 32px;
    height: 32px;

    rect {
      fill: #151515;
    }
  }
`;

export const ImagesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  padding: 95px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  background: #ffffff;
  animation: ${(props: WrapperProps) =>
    props.open
      ? css`
          ${fadeIn} 300ms linear
        `
      : css`
          ${fadeOut} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

interface ImageProps {
  nOfImages: number;
  fixed: FixedObject | FixedObject[];
}

export const Image = styled(Img)`
  width: 100%;
  max-width: 100vw;
  max-width: 100%;
  max-height: ${(props: ImageProps) =>
    props.nOfImages > 4 ? `${100 / props.nOfImages}%` : "20%"};
  margin: 0 auto;
  margin-bottom: 64px;

  &:last-of-type {
    margin-bottom: 0;
  }

  img {
    margin-bottom: 0;
  }
`;
