import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  Title,
  Subtitle,
  TechnologiesWrapper,
  ItemWrapper,
  TextWrapper,
  Text,
  HorizontalDivider,
  CloseModalWrapper,
  ImagesWrapper,
  Image,
} from "./style";
import { DataType, DataKeysType } from "../utils";
import { PageChange } from "../../../../../common/Scroll";
import Modal from "../../../../../common/Modal";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import CloseIcon from "-!svg-react-loader!../../../../../../assets/images/closeIcon.svg";
import { PageTitle } from "../../../../../common/PageTitle";

interface TechnologiesProps {
  pageChange: PageChange;
  transitionBlocks: JSX.Element[][];
  setOpen: (value: boolean) => void;
}

const TechnologiesMobile: React.FC<TechnologiesProps> = ({
  pageChange,
  setOpen,
}) => {
  const [transitionOpen, setTransitionOpen] = useState<boolean>(false);
  const [openModal, setModalOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<DataKeysType | null>(
    null
  );

  const { t } = useTranslation("main");
  const data = useStaticQuery(imagesQuery) as DataType;
  const categories = Object.keys(data) as DataKeysType[];

  const handleOpenModal = (category: DataKeysType) => {
    setOpen(true);
    setTransitionOpen(true);
    setModalOpen(true);
    setSelectedCategory(category);
  };

  const handleCloseModal = () => {
    setTransitionOpen(false);
    setTimeout(() => {
      setOpen(false);
      setModalOpen(false);
      setSelectedCategory(null);
    }, 600);
  };

  return (
    <>
      <PageTitle subtitle={fixConjunctions(t(`technologies_page_title`))} />
      <PageWrapper animation={pageChange}>
        <Title
          dangerouslySetInnerHTML={{
            __html: fixConjunctions(t(`technologies_title`)),
          }}
          color="#013CFF"
        />
        <Subtitle
          dangerouslySetInnerHTML={{
            __html: fixConjunctions(t(`technologies_subtitle`)),
          }}
        />
        <Subtitle
          dangerouslySetInnerHTML={{
            __html: fixConjunctions(t(`technologies_description`)),
          }}
        />
        <TechnologiesWrapper>
          {categories.map((category, index) => (
            <ItemWrapper key={index} onClick={() => handleOpenModal(category)}>
              <TextWrapper>
                <Text>{t(category)}</Text>
                <HorizontalDivider />
              </TextWrapper>
            </ItemWrapper>
          ))}
        </TechnologiesWrapper>
      </PageWrapper>
      <Modal
        open={!!selectedCategory}
        handleClose={handleCloseModal}
        style={{ background: "none", overflowY: "auto", width: "100%" }}
      >
        <CloseModalWrapper open={transitionOpen ? 1 : 0}>
          <CloseIcon onClick={handleCloseModal} />
        </CloseModalWrapper>
        <ImagesWrapper open={transitionOpen ? 1 : 0}>
          {selectedCategory &&
            data[selectedCategory] &&
            data[selectedCategory].nodes.map((image, index) => (
              <Image
                fixed={image.childImageSharp.fixed}
                key={index}
                nOfImages={data[selectedCategory].nodes.length}
              />
            ))}
        </ImagesWrapper>
      </Modal>
    </>
  );
};

export const imagesQuery = graphql`
  {
    tvSystem: allFile(
      filter: { relativeDirectory: { eq: "technologies/SmartTV" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
    IOTAndSmartCity: allFile(
      filter: { relativeDirectory: { eq: "technologies/IOTAndSmartCity" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
    mobileApps: allFile(
      filter: { relativeDirectory: { eq: "technologies/mobileApps" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
    bigData: allFile(
      filter: { relativeDirectory: { eq: "technologies/bigData" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
    databasesAndBackOfficeSystems: allFile(
      filter: {
        relativeDirectory: { eq: "technologies/databasesAndBackOfficeSystems" }
      }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
    videoStreamAnalysis: allFile(
      filter: { relativeDirectory: { eq: "technologies/videoStreamAnalysis" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
    qualityAssuranceAndArchitecture: allFile(
      filter: {
        relativeDirectory: {
          eq: "technologies/qualityAssuranceAndArchitecture"
        }
      }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
    businessAnalytics: allFile(
      filter: { relativeDirectory: { eq: "technologies/businessAnalytics" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  }
`;

export default TechnologiesMobile;
